// const makeNavLinksSmooth = () => {
//   const navLinks = document.querySelectorAll('nav a');
//   // const navLinks = document.querySelectorAll('nav a, .scroll-to');

//   for (let n in navLinks) {
//     if (navLinks.hasOwnProperty(n)) {
//       navLinks[n].addEventListener( 'click', e => {
//         e.preventDefault();

//         document.querySelector(navLinks[n].hash)
//           .scrollIntoView({
//             behavior: "smooth"
//           });
//       } );
//     }
//   }
// }


const spyScrolling = () => {
  const sections = document.querySelectorAll('.section-banner, .section-menu, .section-reviews, .section-location, .section-reservation');

  window.onscroll = () => {
    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

    for(let s in sections) {
      if (sections.hasOwnProperty(s) && sections[s].offsetTop <= scrollPos) {
        const id = sections[s].id;
        const activeElements = document.querySelector('.active');
        if (activeElements) {
          activeElements.classList.remove('active');
        }
        document.querySelector(`a[href*=${ id }]`).classList.add('active');
      }
    }
  } 
}

// makeNavLinksSmooth();
spyScrolling();

class MobileMenuHandler {
  constructor() {
    this._setListeners();
    this._isVisible = false;
    this._viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  }

  isVisible() {
    return this._isVisible;
  }

  show() {
    if (this.isVisible()) {
      return false;
    }

    this._isVisible = true;
    this._updateMenu();

    return true;
  }

  hide() {
    if (!this.isVisible()) {
      return false;
    }

    this._isVisible = false;
    this._updateMenu();

    return true;
  }

  toggle() {
    if (this.isVisible()) {
      this.hide();
    } else {
      this.show();
    }
  }

  _setListeners() {
    const self = this;
    const nodeListToggle = document.querySelectorAll('.toggle-mobile-menu');
    if (nodeListToggle) {
      nodeListToggle.forEach((nodeEl) => {
        nodeEl.addEventListener("click", (e) => {
          e.preventDefault();
          self.toggle();
          return false;
        }, false);
      });
    }

    const mobileLinks = document.querySelectorAll('.mobile-menu-link');
    if (mobileLinks) {
      mobileLinks.forEach((nodeEl) => {
        nodeEl.addEventListener("click", (e) => {
          self.hide();
        }, false);
      });
    }

    window.addEventListener('resize', () => { self._handleResize(); });
  }

  _handleResize() {
    this._viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    this._updateMenu();
  }

  _updateMenu() {
    if (this.isVisible() && this._viewportWidth < 992) {
      document.querySelector('.mobile-menu').classList.add('is-visible');
      document.querySelector('.hamburger-icon').classList.add('is-active');
    } else {
      document.querySelector('.mobile-menu').classList.remove('is-visible');
      document.querySelector('.hamburger-icon').classList.remove('is-active');
    }
  }
}

const mobileMenu = new MobileMenuHandler();
window.mobileMenu = mobileMenu;